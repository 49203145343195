export const appTypes = {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',

  SHOW_ERROR: 'SHOW_ERROR',
  HIDE_ERROR: 'HIDE_ERROR'
};

export const userTypes = {
  USER_API_URL: 'users',

  FETCH_ALL_USERS_ERROR: 'FETCH_ALL_USERS_ERROR',
  FETCH_ALL_USERS_SUCCESS: 'FETCH_ALL_USERS_SUCCESS',

  FETCH_USER_ERROR: 'FETCH_USER_ERROR',
  FETCH_USER_SUCCESS: 'FETCH_USERCESS',

  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',

  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',

  ADD_ROLE_USER_SUCCESS: 'ADD_ROLE_USER_SUCCESS',
  ADD_ROLE_USER_ERROR: 'ADD_ROLE_USER_ERROR',

  REMOVE_ROLE_USER_SUCCESS: 'REMOVE_ROLE_USER_SUCCESS',
  REMOVE_ROLE_USER_ERROR: 'REMOVE_ROLE_USER_ERROR',
};

export const postTypes = {
  FETCH_POSTS_ERROR: 'FETCH_POSTS_ERROR',
  FETCH_POSTS_SUCCESS: 'FETCH_POSTS_SUCCESS',

  FETCH_POSTS_BY_CATEGORY_ERROR: 'FETCH_POSTS_BY_CATEGORY_ERROR',
  FETCH_POSTS_BY_CATEGORY_SUCCESS: 'FETCH_POSTS_BY_CATEGORY_SUCCESS',

  DELETE_POST_SUCCESS: 'DELETE_POST_SUCCESS',
  DELETE_POST_ERROR: 'DELETE_POST_ERROR',

  CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  CREATE_POST_ERROR: 'CREATE_POST_ERROR',

  UPDATE_POST_SUCCESS: 'UPDATE_POST_SUCCESS',
  UPDATE_POST_ERROR: 'UPDATE_POST_ERROR',

  CREATE_POST_CAT_SUCCESS: 'CREATE_POST_CAT_SUCCESS',
  CREATE_POST_CAT_ERROR: 'CREATE_POST_CAT_ERROR'
};
